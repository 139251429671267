@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: NeueKabel;
    src: url("../utils/NeueKabel.otf") format("opentype");
}


html,
body {
    /* overflow: hidden; */
}

::-webkit-scrollbar {
    display: none;
}

/* Global Styles */
body {
    background-color: #1e1e1e;
    color: #ffffff;
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: NeueKabel;
}

/* Login Container */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: #1e1e1e; */
    color: #ffffff;
}

.login-container h2 {
    margin-bottom: 20px;
}

.login-container input {
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    border: none;
    text-align: center;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: rgb(255, 255, 255);
    font-size: 25px;
    background-color: transparent;
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
}

/* .login-container button {
    padding: 5px 20px;
    height: 40px;
    width: 90px;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    background-color: #000000;
    color: #ffffff;
    box-shadow: #ffffff -5px 6px 0px;
    cursor: pointer;
}

.login-container button:hover {
    background-color: #000000;
    color: #ffffff;
    box-shadow: #ffffff -2.5px 3px 0px;
    cursor: pointer;
} */

/* Workspace Container */
.workspace-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* background-color: #1e1e1e; */
    height: 100vh;
    color: #ffffff;
}

.actions {
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
}

.actions button {
    margin: 5px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.actions button.start-work {
    background-color: #317842;
}

.actions button.start-break,
.actions button.end-break {
    background-color: #c46f15;
}

.actions button.end-work {
    background-color: #c34a56;
}

.actions button.add-description {
    background-color: #9fa3a6;
}

.actions button:hover {
    opacity: 0.7;
}

/* Timeline Styles */
.vertical-timeline-element-content {
    background-color: #2e2e2e;
    padding-top: 10px;
    padding-bottom: 10px;
}

.vertical-timeline-element-content h3 {
    color: #ffffff;
}

.vertical-timeline-element-date {
    margin-bottom: 0;
    /* color: #888888; */
}

/* Timeline Container */
.timeline-container {
    width: 100%;
    overflow-y: scroll;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;

    /* keep scrolled to bottom */
    /* display: flex;
    flex-direction: column-reverse; */

    box-shadow: inset 0px 0 10px 0px #242424;

    /* hidding scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.timeline-container::-webkit-scrollbar {
    display: none;
}

.timeline-container {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.timeline-container::-webkit-scrollbar {
    width: 6px;
}

.timeline-container::-webkit-scrollbar-track {
    background: transparent;
}

.timeline-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

.vertical-timeline-element-icon {
    box-shadow: none !important;
}


.timer {
    text-align: center;
    /* background-color: #282c34; */
    color: #ffffff;
    /* padding: 10px; */
    border-radius: 8px;
    backdrop-filter: blur(2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.timer h3 {
    font-size: 18px;
    color: #ffffff;
}

.timer p {
    margin: 0;
    font-size: 30px;
    /* font-family: 'Courier New', Courier, monospace; */
    color: white;
}

.bg-danger {
    background-color: #c34a56;
}

.loading {
    display: flex;
    height: 100vh;
    width: 100vw;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(30px);
    z-index: 99;
}

.strong-tilt-move-shake {
    animation: tilt-n-move-shaking 0.10s infinite;
}

@keyframes tilt-n-move-shaking {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    25% {
        transform: translate(2px, 2px) rotate(1deg);
    }

    50% {
        transform: translate(0, 0) rotate(0eg);
    }

    75% {
        transform: translate(-2px, 2px) rotate(-1deg);
    }

    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

.bg-grey {
    background-color: var(--bs-secondary);
    color: white;
}

.bg-transparent {
    background: transparent;
    backdrop-filter: blur(10px);
}

.bg-transparent-noblur {
    background: transparent !important;
    background-color: transparent !important;
}

.bg-transparent-noblur .rv-popover__content {
    background: transparent !important;
}

.rv-button__content {
    background: transparent;
    backdrop-filter: blur(10px);
}

.alert {
    font-size: 20px;
}

.work-info p {
    font-size: 18px;
    color: #c4c4c4;
}

.work-info p span {
    font-size: 25px;
}

.work-info p span:first-of-type {
    color: #36eb60;
    font-weight: 600;
    text-shadow: 0px 0 4px #36eb6096;
}

.work-info p span:last-of-type {
    color: #faa447;
    font-weight: 600;
    text-shadow: 0px 0 4px #faa4477a;
}

.work-info svg {
    max-height: 15px;
    cursor: pointer;
}

.version {
    margin-bottom: 0;
    font-size: small;
    bottom: 0;
    position: fixed;
}

.version p {
    margin-bottom: 0;
}

.version p:hover {
    color: white;
}

.event_edit .rv-dialog.rv-popup {
    border-radius: 0px;
}

.event_edit .rv-dialog__header, .rv-dialog__content {
    background-color: rgb(36, 39, 51);
}

.event_edit .rv-dialog__header {
    padding: 10px;
}

.event_edit .rv-button__content {
    backdrop-filter: none;
}

.event_edit .rv-dialog__cancel {
    background-color: #2e2e2e;
    color: white;
}

/* .rv-dialog__confirm {
    background-color: #1e1e1e;
    color: white;
} */
